<template>
	<div class="scientificBox">
		<div class="container flex center_center">
			<classifyBox :title='"科研动态"' :classifyList='scientificClassify' :activeIndex='scientificIndex' :firstId='7' :type='1' @getDataListFn='getDataFn'></classifyBox>
			<div class="scientificNews flex">
				<newsLineCom :newsList='scientificNewsList' :firstId='7' :secondId='secondId' :type='1'></newsLineCom>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		computed,
		onMounted,
		ref
	} from 'vue'
	import {
		scientificIndexListApiFn,
		getNavIndexTwoPageApiFn
	} from '@/utils/home.js'
	import classifyBox from './com/classifyBox.vue'
	import newsLineCom from './com/news.vue'
	const scientificNewsList = ref([]);
	const scientificClassify = ref([]);
	let scientificIndex = ref(-1);
	let secondId = computed(() => {
		// console.log(scientificIndex.value, scientificClassify.value.length)
		return scientificIndex.value == scientificClassify.value.length ? 7 : scientificIndex.value == -1 ? 0 :
			scientificClassify.value[scientificIndex.value].id;
	})
	onMounted(async () => {
		const res = await getNavIndexTwoPageApiFn({
			id: 7,
			page: 1,
			page_size: 7
		});
		scientificClassify.value = res.data.list;
		await getDataFn(res.data.list.length)
	})
	const getDataFn =async (index) => {
		scientificIndex.value = index;
		const data = {
			id: scientificIndex.value == scientificClassify.value.length ? 7 : scientificClassify.value[
				scientificIndex.value].id,
			page: 1,
			page_size: 24
		}
		const res = await scientificIndexListApiFn(data);
		scientificNewsList.value =res.data.list
	}
</script>

<style lang="scss" scoped>
	.scientificBox {
		.container {
			height: 420px;

			.scientificNews {
				flex: 1;
				margin-left: 10px;
				
				flex-wrap: wrap;
				height: 360px;

				:deep() {
					.one-item {
						width: 32.5%;
						margin-right: 1.25%;
					}
					.one-item:nth-of-type(3){
						margin-right: 0px;
					}
				}
			}
			
		}

	}
</style>
