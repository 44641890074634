<template>
	<div class="bigBox">
		<div class="container">
			<div class="logoBox flex between_center">
				<img class="logo" :src="logo" />
				<div class="tips">为确保账户的安全及正常使用,依《网络安全法》相关要求,会员账户需绑定手机,感谢您的理解及支持!
				</div>
			</div>
			<div class="centerBox flex">
				<div class="leftBox">
					<p class="text1">业迈生物</p>
					<p class="text2">一站式采购 为科研保驾护航</p>
					<img src="../../assets/img/login3.png" />
				</div>
				<div class="rightBox flex">
					<template v-if='type == "login"'>
						<div class="title">
							会员登录
							<span class="circle1"></span>
							<span class="circle2"></span>
						</div>
						<!-- <div class="typeBox flex center_center">
							<div class="one_item" :class="loginType == 0 ? 'active':''" @click="loginType = 0">账号登录
							</div>
							<div class="one_item" :class="loginType == 1 ? 'active':''" @click="loginType = 1">手机登录
							</div>
						</div> -->
						<template v-if='loginType == 0'>
							<!-- 账号登录 -->
							<div class="lineBox">
								<img src="../../assets/icon/antOutline-user.png" />
								账号/手机号码
							</div>
							<el-input class="inputBox" v-model="accountValue.phone" placeholder="请输入手机号码 / 账号"
								clearable />
							<div class="lineBox">
								<img src="../../assets/icon/iconPark-lock.png" />
								密码
							</div>
							<el-input type="password" show-password class="inputBox" v-model="accountValue.pwd"
								placeholder="请输入登录密码" />
							<div class='agreeCirBox flex'>
								<div class="agreeCir">
									<el-checkbox v-model="accountValue.agreeFlag" label="" size="small" />
									同意<span @click="goPage(9)">《平台服务协议》</span>和<span @click="goPage(10)">《隐私条款》</span>
								</div>
								<!-- <span @click="type ='forgetPwd'">忘记密码？</span> -->
								<span @click="dialogVisible=true">忘记密码？</span>
							</div>
							<div class="loginBtn" @click="accountLogin()">登录</div>
							<div class="tipsText">没有账号？<span @click="type ='register'">立即注册</span></div>
						</template>
						<template v-else-if='loginType == 1'>
							<!-- 手机号码登录 -->
							<div class="lineBox">
								<img src="../../assets/icon/ze-mobile.png" />
								手机号码
							</div>
							<div class="input_bigBox flex">
								<div class="tips">
									+86
									<img src="../../assets/icon/arcoDesign-caret-down.png" />
								</div>
								<el-input class="inputBox" v-model="phoneValue.phone" placeholder="请输入手机号码 / 账号"
									clearable />
							</div>
							<div class="lineBox">
								<img src="../../assets/icon/stLine-shield.png" />
								验证码
							</div>
							<div class="input_bigBox flex">
								<el-input class="inputBox" v-model="phoneValue.code" placeholder="请输入验证码" />
								<div class='getCodeText' @click="sendMsgStart('login')">
									{{phoneValue.sendMsgFlag ? phoneValue.remainingTime +'s' :'获取验证码'}}
								</div>
							</div>

							<div class='agreeCirBox flex'>
								<div class="agreeCir">
									<el-checkbox v-model="phoneValue.agreeFlag" label="" size="small" />
									同意<span @click="goPage(9)">《平台服务协议》</span>和<span @click="goPage(10)">《隐私条款》</span>
								</div>
							</div>
							<div class="loginBtn" @click="loginPhone">登录</div>
							<div class="tipsText">没有账号？<span @click="type ='register'">立即注册</span></div>
						</template>
					</template>
					<template v-else-if='type == "register"'>
						<div class="title">
							注册会员
							<span class="circle1"></span>
							<span class="circle2"></span>
						</div>
						<div class="lineBox">
							<img src="../../assets/icon/ze-mobile.png" />
							手机号码
						</div>
						<div class="input_bigBox flex">
							<div class="tips">
								+86
								<img src="../../assets/icon/arcoDesign-caret-down.png" />
							</div>
							<el-input class="inputBox" v-model="registerValue.phone" placeholder="请输入手机号码 / 账号"
								clearable />
						</div>
						<!-- <div class="lineBox">
							<img src="../../assets/icon/stLine-shield.png" />
							验证码
						</div>
						<div class="input_bigBox flex">
							<el-input class="inputBox" v-model="registerValue.code" placeholder="请输入验证码" />
							<div class='getCodeText' @click="sendMsgStart('register')">
								{{registerValue.sendMsgFlag ? registerValue.remainingTime +'s' :'获取验证码'}}
							</div>
						</div> -->
						<div class="lineBox">
							<img src="../../assets/icon/iconPark-lock.png" />
							密码
						</div>
						<el-input type="password" show-password class="inputBox" v-model="registerValue.pwd"
							placeholder="请输入登录密码" />
						<div class='agreeCirBox flex'>
							<div class="agreeCir">
								<el-checkbox v-model="registerValue.agreeFlag" label="" size="small" />
								同意<span @click="goPage(9)">《平台服务协议》</span>和<span @click="goPage(10)">《隐私条款》</span>
							</div>
						</div>
						<div class="loginBtn" @click="registerFn()">注册</div>
						<div class="tipsText">已有账号？<span @click="type ='login'">立即登录</span></div>
					</template>
					<template v-else-if='type == "forgetPwd"'>
						<div class="title">
							重置密码
							<span class="circle1"></span>
							<span class="circle2"></span>
						</div>
						<div class="lineBox">
							<img src="../../assets/icon/ze-mobile.png" />
							手机号码
						</div>
						<div class="input_bigBox flex">
							<div class="tips">
								+86
								<img src="../../assets/icon/arcoDesign-caret-down.png" />
							</div>
							<el-input class="inputBox" v-model="forgetValue.telephone" placeholder="请输入手机号码 / 账号"
								clearable />
						</div>
						<div class="lineBox">
							<img src="../../assets/icon/stLine-shield.png" />
							验证码
						</div>
						<div class="input_bigBox flex">
							<el-input class="inputBox" v-model="forgetValue.code" placeholder="请输入验证码" />
							<div class='getCodeText' @click="sendMsgStart('password')">
								{{forgetValue.sendMsgFlag ? forgetValue.remainingTime +'s' :'获取验证码'}}
							</div>
						</div>
						<div class="lineBox">
							<img src="../../assets/icon/iconPark-lock.png" />
							密码
						</div>
						<el-input type="password" show-password class="inputBox" v-model="forgetValue.password"
							placeholder="请输入新密码" />
						<div class="lineBox">
							<img src="../../assets/icon/iconPark-lock.png" />
							确认密码
						</div>
						<el-input type="password" show-password class="inputBox" v-model="forgetValue.rep_password"
							placeholder="请确认登录密码" />
						<div class='agreeCirBox flex'>
							<div class="agreeCir">
								<el-checkbox v-model="forgetValue.agreeFlag" label="" size="small" />
								同意<span @click="goPage(9)">《平台服务协议》</span>和<span @click="goPage(10)">《隐私条款》</span>
							</div>
						</div>
						<div class="loginBtn" @click="postEditPassApiFn()">重置密码</div>
						<div class="tipsText"><span @click="type ='login'">暂不修改密码</span></div>
					</template>
				</div>
			</div>
			<div class="bottomBox">
				版权所有：{{ firm }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index"
					target="_blank">ICP备案证书号：冀ICP备2024050421号-1</a><br />
				服务热线：{{Service_hotline}}
			</div>
		</div>
	</div>
	<el-dialog v-model="dialogVisible" title="客服微信" width="500">
		<img style="width: 400px;margin: 0 auto;display: block;" :src='customer_service' />
		<div style="line-height: 40px;text-align: center;">添加客服微信，联系后台更改密码。</div>
	</el-dialog>
</template>

<script setup>
	import {
		ElMessage
	} from 'element-plus';
	import {
		userLoginApi,
		getUserInfoApi,
		postSendsmsApi,
		postRegisterApi,
		postEditPassApi
	} from '@/api/index'
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	import {
		onMounted,
		ref
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import {
		useStore
	} from 'vuex';
	const store = useStore();
	const route = useRoute();
	const router = useRouter();
	let type = ref('login');
	let customer_service = ref('');
	let logo = ref('');
	let Service_hotline = ref('');
	let dialogVisible = ref(false);
  let firm = ref("")
	onMounted(async () => {
		if (route.query.type == 2) {
			type.value = 'register'
		} else {
			type.value = 'login'
		}
		customer_service.value = await getInfoApiFn(15);
		customer_service.value = JSON.parse(customer_service.value)[0].url
		Service_hotline.value = await getInfoApiFn(11);
		logo.value = await getInfoApiFn(12);
    firm.value =await getInfoApiFn(23);
		logo.value = JSON.parse(logo.value)[0].url
	})
	let loginType = ref(0);
	const accountValue = ref({
		phone: '',
		pwd: '',
		agreeFlag: false
	})
	const phoneValue = ref({
		phone: '',
		code: '',
		agreeFlag: false,
		sendMsgFlag: false,
		remainingTime: 60,
	})
	const registerValue = ref({
		phone: '',
		code: '',
		agreeFlag: false,
		sendMsgFlag: false,
		pwd: '',
		remainingTime: 60,
	})
	const forgetValue = ref({
		telephone: '',
		code: '',
		agreeFlag: false,
		sendMsgFlag: false,
		password: '',
		rep_password: '',
		remainingTime: 60,
	})
	const postEditPassApiFn = () => {
		if (!forgetValue.value.agreeFlag) {
			ElMessage.error('请勾选同意网站协议');
			return;
		}
		// 重置密码
		const data = {
			telephone: forgetValue.value.telephone,
			code: forgetValue.value.code,
			password: forgetValue.value.password,
			rep_password: forgetValue.value.rep_password
		}
		postEditPassApi(data).then(res => {
			ElMessage({
				type: 'success',
				message: '重置成功,快去登录吧',
				onClose() {
					type.value = 'login'
				}
			});
		})
	}
	// 账号登录
	const accountLogin = () => {
		if (!accountValue.value.agreeFlag) {
			ElMessage.error('请勾选同意网站协议');
			return;
		}
		const data = {
			is_type: 1,
			telephone: accountValue.value.phone,
			password: accountValue.value.pwd,
		}
		loginFn(data)
	}
	const loginFn = async (data) => {
		const res = await userLoginApi(data)
		// console.log("res", res)
		localStorage.setItem('token', res.data.accessToken);
		getUserInfoApiFn();
	}
	// 注册
	const registerFn = () => {
		if (!registerValue.value.agreeFlag) {
			ElMessage.error('请勾选同意网站协议');
			return;
		}
		const data = {
			telephone: registerValue.value.phone,
			code: registerValue.value.code,
			password: registerValue.value.pwd
		}
		postRegisterApi(data).then(res => {
			ElMessage.success('注册成功，快去登录吧！')
		})
	}
	let timer = ref(null);
	const sendMsgStart = async (type) => {
		if (type == 'login') {
			if (phoneValue.value.sendMsgFlag) {
				return;
			}
			const data = {
				telephone: phoneValue.value.phone
			}
			await postSendsmsApi(data);
			timer.value = setInterval(() => {
				if (phoneValue.value.remainingTime == 1) {
					clearInterval(timer.value)
					timer.value = null
					phoneValue.value.sendMsgFlag = false;
				} else {
					phoneValue.value.sendMsgFlag = true;
					phoneValue.value.remainingTime -= 1
				}
			}, 1000);
		} else if (type == 'register') {
			if (registerValue.value.sendMsgFlag) {
				return;
			}
			const data = {
				telephone: registerValue.value.phone
			}
			await postSendsmsApi(data);
			timer.value = setInterval(() => {
				if (registerValue.value.remainingTime == 1) {
					clearInterval(timer.value)
					timer.value = null
					registerValue.value.sendMsgFlag = false;
				} else {
					registerValue.value.sendMsgFlag = true;
					registerValue.value.remainingTime -= 1
				}
			}, 1000);
		} else {
			if (forgetValue.value.sendMsgFlag) {
				return;
			}
			const data = {
				telephone: forgetValue.value.phone
			}
			await postSendsmsApi(data);
			timer.value = setInterval(() => {
				if (forgetValue.value.remainingTime == 1) {
					clearInterval(timer.value)
					timer.value = null
					forgetValue.value.sendMsgFlag = false;
				} else {
					forgetValue.value.sendMsgFlag = true;
					forgetValue.value.remainingTime -= 1
				}
			}, 1000);
		}
	}
	const loginPhone = async () => {
		if (!phoneValue.value.agreeFlag) {
			ElMessage.error('请勾选同意网站协议');
			return;
		}
		const data = {
			is_type: 2,
			telephone: phoneValue.value.phone,
			code: phoneValue.value.code,
		}
		const res = await userLoginApi(data)
		localStorage.setItem('token', res.data.accessToken);
		getUserInfoApiFn();
	}
	const getUserInfoApiFn = () => {
		getUserInfoApi().then(res => {
			// console.log("res", res);
			store.state.userInfo = JSON.stringify(res.data);
			localStorage.setItem('userInfo', JSON.stringify(res.data))
			ElMessage({
				type: 'success',
				message: '登录成功！',
				onClose() {
					router.push({
						name: 'home',
					})
				}
			})
		})
	}
	const goPage = (type) => {
		// console.log('type', type)
		router.push({
			name: 'agreement',
			query: {
				type
			}
		})
	}
</script>

<style lang="scss" scoped>
	.bigBox {
		width: 100vw;
		min-width: 1680px;
		height: 100vh;
		background-image: url('../../assets/img/login.png');
		background-repeat: repeat;

		.container {
			width: calc(100% - 480px);
		}

		.bottomBox {
			position: fixed;
			bottom: 2.5vh;
			left: 0px;
			right: 0px;
			margin: auto;
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: AlibabaPuHui-regular;

			a {
				color: #fff;
			}
		}

		.logoBox {
			padding-top: 2.7vh;

			.logo {
				// width: 9vh;
				height: 9vh;
				border-radius: 9vh;
			}

			.tips {
				padding: 0 20px;
				height: 4.1vh;
				line-height: 4.1vh;
				border-radius: 4px;
				background-color: rgba(211, 14, 14, 0.11);
				color: rgba(211, 14, 14, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: right;
				font-family: Roboto;
				border: 1px solid rgba(255, 115, 21, 1);
			}
		}

		.centerBox {
			margin-top: 11vh;

			.leftBox {
				width: 816px;
				height: 58vh;
				border-radius: 8px 0 0 8px;
				background-image: url('../../assets/img/login2.png');
				background-repeat: repeat;
				padding: 7.4vh 0 0 84px;
				box-sizing: border-box;

				.text1 {
					color: rgba(64, 149, 229, 1);
					font-size: 60px;
					font-weight: bolder;
					font-family: SourceHanSansSC-black;
				}

				.text2 {
					color: rgba(64, 149, 229, 1);
					font-size: 28px;
					font-family: SourceHanSansSC-medium;
				}

				img {
					display: block;
					height: 31vh;
					margin: 1.67vh auto 0;
				}
			}

			.rightBox {
				border-radius: 0 8px 8px 0;
				background-color: rgba(255, 255, 255, 1);
				flex: 1;
				padding: 0 60px;
				box-sizing: border-box;
				flex-direction: column;
				justify-content: center;

				.title {
					width: 110px;
					color: rgba(16, 16, 16, 1);
					font-size: 24px;
					font-weight: bolder;
					font-family: AlibabaPuHui-bold;
					position: relative;
					margin: 0 auto;

					.circle1 {
						position: absolute;
						top: 0px;
						left: -17px;
						width: 17px;
						height: 17px;
						background: linear-gradient(180deg, rgba(199, 216, 254, 1) 0%, rgba(199, 216, 216, 0) 100%);
						border-radius: 100%;
					}

					.circle2 {
						position: absolute;
						width: 26px;
						height: 26px;
						bottom: -10px;
						right: -10px;
						border-radius: 100%;
						background: linear-gradient(153.55deg, rgba(199, 216, 216, 0) -3.17%, rgba(199, 216, 254, 1) 83.39%);
					}
				}

				.typeBox {
					margin-top: 4vh;
					margin-bottom: 20px;

					.one_item {
						height: 30px;
						color: rgba(16, 16, 16, 1);
						font-size: 18px;
						font-family: AlibabaPuHui-medium;
						cursor: pointer;
					}

					.one_item:nth-of-type(1) {
						margin-right: 50px;
					}

					.one_item.active {
						color: rgba(64, 149, 229, 1);
						position: relative;

					}

					.one_item.active::after {
						position: absolute;
						bottom: 0px;
						left: 0px;
						right: 0px;
						margin: auto;
						content: '';
						width: 80%;
						height: 2px;
						background-color: rgba(64, 149, 229, 1);
					}
				}

				.lineBox {
					color: rgba(51, 51, 51, 1);
					font-size: 14px;
					font-weight: bolder;
					text-align: left;
					font-family: SourceHanSansSC-regular;

					img {
						width: 16px;
						height: 16px;
						margin-right: 4px;
						vertical-align: middle;
					}
				}

				.inputBox {
					margin-top: 10px;
					outline: none;
					margin-bottom: 15px;
				}

				:deep(.el-input__wrapper) {
					background-color: #F2F4FB;
					box-shadow: none !important;
				}

				.agreeCirBox {
					margin-top: 10px;

					.agreeCir {
						flex: 1;
						font-size: 12px;

						span {
							color: #4095E5;
						}
					}

					span {
						color: rgba(51, 51, 51, 1);
						font-size: 12px;
						cursor: pointer;
					}

					:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
						background-color: #4095E5;
						border-color: #4095E5;
						border-radius: 12px;
					}

					:deep(.el-checkbox__inner) {
						border-radius: 12px;
					}
				}

				.loginBtn {
					cursor: pointer;
					margin-top: 14px;
					height: 4.6vh;
					line-height: 4.6vh;
					border-radius: 4px;
					background-color: rgba(64, 149, 229, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 16px;
					text-align: center;
					font-family: Roboto;
				}

				.input_bigBox {
					align-items: center;
					margin: 10px 0 15px;

					.tips {
						width: 82px;
						height: 32px;
						line-height: 32px;
						border-radius: 4px;
						background-color: rgba(242, 244, 251, 1);
						color: rgba(190, 190, 190, 1);
						font-size: 14px;
						text-align: center;
						font-family: Roboto;
						margin-right: 10px;

						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
						}
					}

					.inputBox {
						margin: 0;
					}

					.getCodeText {
						cursor: pointer;
						margin-left: 10px;
						width: 138px;
						height: 30px;
						line-height: 30px;
						border-radius: 4px;
						color: rgba(64, 149, 229, 1);
						font-size: 14px;
						text-align: center;
						font-family: Roboto;
						border: 1px solid rgba(64, 149, 229, 1);
					}
				}

				.tipsText {
					margin-top: 30px;
					color: rgba(51, 51, 51, 1);
					font-size: 12px;
					text-align: center;
					font-family: AlibabaPuHui-regular;

					span {
						color: rgba(64, 149, 229, 1);
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
