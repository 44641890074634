<template>
	<div class="scientificBox">
		<div class="container flex center_center">
			<classifyBox :title='"文章解读"' :classifyList='articleClassify' :activeIndex='articleIndex' :firstId='firstId' :type='type' @getDataListFn='getDataFn'></classifyBox>
			<div class="scientificNews">
				<newsLineCom :newsList='articleList' :firstId='firstId' :secondId='secondId' :type='type'></newsLineCom>
			</div>
			<div class="teaching">
				<div class="title flex between_center">
					<span class="span1">教学培训</span>
					<span class="span2" @click="goMore">查看更多 &gt;</span>
				</div>
				<div class="teachingSwiper">
					<swiper style="height: 315px;" :navigation="teachingSwiper_navigation" :loop='true'
						:modules="teachingSwiper_modules" :slides-per-view="3" :space-between="5">
						<swiper-slide v-for="(item,index) in teachingList">
							<div class="item_one" @click='goPage(item)'>
								<img class='item_one_img' :src="item.cover_url" />
								<div class="item_on_title flex">
									<p class="line1">{{item.title}}</p>
									<text
										:class="item.class_id % 2!=0?'line1 type_shuang':'line1 type_dan'">{{item.class_name}}</text>
								</div>
								<div class="item_on_info line3">{{item.summary}}</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="teaching-swiper-button teaching-swiper-button-prev">
						<img src="../../assets/icon/ze-arrow-left-gray.png" />
					</div>
					<div class="teaching-swiper-button teaching-swiper-button-next">
						<img src="../../assets/icon/ze-arrow-left-gray.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		computed,
		onMounted
	} from 'vue'
	import {
		scientificIndexListApiFn,
		getNavIndexTwoPageApiFn
	} from '@/utils/home.js'
	import classifyBox from './com/classifyBox.vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Navigation
	} from "swiper/modules";
	import 'swiper/css';
	import "swiper/scss/navigation";
	import newsLineCom from './com/news.vue'
	const firstId = 11;
	const type=1;
	const articleList = ref([]);
	const articleClassify = ref([]);
	let articleIndex = ref(-1);
	let secondId = computed(() => {
		return articleIndex.value == articleClassify.value.length ? 7 : articleIndex.value == -1 ? 0 : articleClassify.value[articleIndex.value].id;
	})
	onMounted(async () => {
		const res = await getNavIndexTwoPageApiFn({
			id: firstId,
			page: 1,
			page_size: 7
		});
		articleClassify.value = res.data.list;
		await getDataFn(res.data.list.length);
		getTeachingListFn()
	})
	const getTeachingListFn =async () => {
		const data = {
			id: 16,
			page: 1,
			page_size: 24
		}
		const res = await scientificIndexListApiFn(data);
		teachingList.value =res.data.list
	}
	const getDataFn =async (index) => {
		articleIndex.value = index;
		const data = {
			id: articleIndex.value == articleClassify.value.length ? firstId : scientificClassify.value[
				articleIndex.value].id,
			page: 1,
			page_size: 8
		}
		const res = await scientificIndexListApiFn(data);
		articleList.value =res.data.list
	}
	const teachingSwiper_navigation = ref({
		nextEl: '.teaching-swiper-button-next',
		prevEl: '.teaching-swiper-button-prev',
	});
	const teachingSwiper_modules = [Navigation];
	const teachingList = ref([]);
	const goPage = (item) => {
		let data={
			infoId:item.id,
			id: 16,
			type: 1
		}
		router.push({
			name: 'scientific_detail',
			query: data
		})
	}
	const goMore = ()=>{
		let data={
			id: 16,
			type: 2
		}
		router.push({
			name: 'scientific_list',
			query: data
		})
	}
</script>

<style lang="scss" scoped>
	.scientificBox {
		background-color: #efefef;

		.container {
			height: 420px;

			.scientificNews {
				margin-left: 10px;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				width: 580px;
				height: 360px;
			}

			.teaching {
				width: calc(100% - 220px - 10px - 580px - 20px);
				margin-left: 20px;

				.title {
					margin-bottom: 15px;

					.span1 {
						line-height: 40px;
						color: rgba(51, 51, 51, 1);
						font-size: 28px;
					}

					.span2 {
						line-height: 20px;
						color: rgba(51, 51, 51, 1);
						font-size: 14px;
						font-family: Roboto;
						cursor: pointer;
					}
				}

				.teachingSwiper {
					position: relative;

					.item_one {
						padding: 10px 10px 15px 10px;
						background-color: #fff;
						border-radius: 4px;
						height: 315px;

						.item_one_img {
							width: 100%;
							height: 195px;
						}

						.item_on_info {
							color: rgba(51, 51, 51, 1);
							font-size: 12px;
							text-align: left;
							font-family: AlibabaPuHui-regular;
						}

						.item_on_title {
							margin: 10px 0;
							align-items: center;

							p {
								max-width: calc(100% - 100px);
								width: auto;
							}

							text {
								max-width: 90px;
								padding: 0 5px;
								box-sizing: border-box;
								height: 24px;
								line-height: 24px;
								border-radius: 4px;
								font-size: 12px;
								text-align: center;
								font-family: Roboto;
								color: rgba(16, 16, 16, 1);
								margin-left: 10px;
							}
						}
					}

					.teaching-swiper-button {
						width: 30px;
						height: 50px;
						position: absolute;
						z-index: 2;
						top: 0px;
						bottom: 0;
						margin: auto;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;

						img {
							width: 20px;
							height: 20px;
						}
					}

					.teaching-swiper-button-prev {
						background-color: rgba(239, 239, 239, 1);
						left: 0px;
						
					}

					.teaching-swiper-button-next {
						background-color: rgba(239, 239, 239, 1);
						right: 0px;
						img{
							transform: rotate(-180deg);
						}
					}
				}
			}
		}

	}
</style>
